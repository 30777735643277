import localforage from 'localforage'
import { isEmpty } from 'lodash'
import { setHttpToken, setHttpBaseUrl } from '../../../helpers'

export const login = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/signin', payload).then((response) => {

        //set User details
        if(response.data.success && payload.keepMeLoggedIn){
            commit('setUser', {
                "email" : payload.email,
                "password" : payload.password,
                "keepMeLoggedIn": payload.keepMeLoggedIn,
                "sso_data":response.data.user,
                "profilePicUrl":response.data.profilePicUrl.profile_pic
            });
        }else{
            commit('setUser', {
                "email" : payload.email,
                "password" : payload.password,
                "keepMeLoggedIn": payload.keepMeLoggedIn,
                "sso_data":response.data.user,
                "profilePicUrl":response.data.profilePicUrl.profile_pic
            });
        }

        // Setting up auth token ad fetching user data
        dispatch('setToken', response.data.token).then(() => {
            const ualPayload = {
                "email" : payload.email,
                "token": response.data.token
            }
        })
        return response.data;
    }).catch((error) => {
        let message = error.response.data.message
        if (isEmpty(message)) {
            message = "Something Went Wrong"
        }
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', message, { root: true })
        context.isbeingLoggedIn = false
        if (!isEmpty(error.response.data.errors)) {
            context.errors = error.response.data.errors
        }
        return false
    })
}

export const loginRESTApi = ({ commit, dispatch },payload) => {

    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/sign-in',payload.payload).then((response) => {
        try{
            if(response.data.success){
                localStorage.setItem('data', JSON.stringify(response.data.user));
                // Showing  success message
                commit('SET_ALERT', 'success', { root: true })
                commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            }else{
                commit('setUser', {
                    "email" : payload.email,
                    "password" : '',
                    "keepMeLoggedIn": payload.keepMeLoggedIn,
                    'data':'',
                    "profilePicUrl":''
                });
            }

            dispatch('fetchUser');
        }catch(error){
            console.log('Rest Login Api error',error);
            commit('setUser', {
                "email" : payload.email,
                "password" : '',
                "keepMeLoggedIn": payload.keepMeLoggedIn,
                "data":'',
                "profilePicUrl":''
            });
        }

        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
        context.errors = error.response.data.errors
        return false
    });
}

export const logout = ({ dispatch },payload) => {
    setHttpBaseUrl(null)
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/logout').then((response) => {
        dispatch('clearAuth').then(()=>{
            dispatch('logoutUnautorizedUser',payload);
        })
    }).catch(e => {
        dispatch('errorHandler',e.response.status);
        console.log('logout error response:', e);
        return e;
    });
}

export const logoutUnautorizedUser = (context,payload) => {
    let userPayload = null;
    let user = null ;
    if(payload !== null){
        user = payload;
    }else{
        user = {
            "email" : localStorage.getItem('email'),
            "password" : localStorage.getItem('password'),
            "token" : '',
            "keepMeLoggedIn": localStorage.getItem('keepMeLoggedIn'),
            "data":'',
            "profilePicUrl":''
        }
    }
    try{
        if(user && user.keepMeLoggedIn === "true") {
            userPayload = {
                "email" : user.email,
                "password" : user.password,
                "token" : '',
                "keepMeLoggedIn": user.keepMeLoggedIn,
                "data":'',
                "profilePicUrl":''
            }
        } else {
            userPayload = {
                "email" : '',
                "password" : '',
                "token" : '',
                "keepMeLoggedIn": false,
                "data":'',
                "profilePicUrl":''
            }
        }
        context.commit('setUser', userPayload);
    }catch($e){
        console.log($e);
    }
}

export const setToken = ({ commit, dispatch }, token) => {
    if (isEmpty(token)) {
        return dispatch('checkTokenExists').then((token) => {
            setHttpToken(token)
            setHttpBaseUrlAfterLogin(token)
        })
    }
    commit('setToken', token)
    setHttpToken(token)
}

export const fetchBellNotifications = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/bell/notify/records').then((response) => {
        commit('setBellNotifications', response.data)
    })
}

export const markNotificationsAsSeen = ({ commit, dispatch }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/bell/notify/mark-seen').then((response) => {
        commit('setBellNotifications', {count: 0, data: []})
    })
}

export const fetchUser = ({ commit, dispatch }) => {

    const email = localStorage.getItem('email');
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/profile-details/'+email).then((response) => {
        commit('setAuthenticated', true);

        let userData = response.data
        if (userData.details.change_password_at_login) {
            commit('showChangePasswordModel', true, { root: true })
        }
        commit('setUserData', response.data)
        // commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH', null, { root: true })
        //dispatch('fetchBellNotifications')
        return response.data;
    })
}

export const checkTokenExists = ({ commit, dispatch }, token) => {
    return localforage.getItem('authtoken').then((token) => {
        if (isEmpty(token)) {
            return Promise.reject('NO_STORAGE_TOKEN')
        }

        return Promise.resolve(token)
    })
}

export const checkIfRoleSelected = ({commit}, context) => {
    commit('CHECK_ROLE_SELECTION', context)
}

export const clearAuth = ({commit}) => {
    commit('setAuthenticated', false)
    commit('setUserData', null)
    commit('setToken', null)
    setHttpToken(null)
    setHttpBaseUrl(null)
    // commit('HANDLE_MAIN_HEADER_BASED_ON_AUTH', null, { root: true })
}

export const setHttpBaseUrlAfterLogin = (token) => {
    return localforage.getItem('authtoken').then((token) => {
        if (isEmpty(token)) {
            return Promise.reject('NO_STORAGE_TOKEN')
        }
        setHttpBaseUrl(token)
        return Promise.resolve(token)
    })
}

export const saveChangePassword = ({ commit, dispatch }, { payload, context }) => {
    const frmData = {
        old_password: payload.oldPassword,
        password: payload.newPassword,
        confirm_password: payload.reNewPassword
    };
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/change-password', frmData).then((response) => {
        if(response){
            commit('SET_ALERT', 'success', { root: true })
            commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
            if(response.data.success){
                const activityLog = window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/activity-log-change-password', frmData).then((res) => {
                    if(res.data.success){
                        
                    }
                }).catch((error) => {
                    commit('SET_ALERT', 'warning', { root: true })
                    commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                    context.errors = error.response.data.errors
                    return false;
                })
            }
            return response;
        }
    }).catch((error) => {
        console.log(error);
        if(error.response.status == 422){
            let laraErrors = error.response.data.errors; // this should be errors.
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', laraErrors.password, { root: true })
        } else if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else {
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            context.errors = error.response.data.errors
            return false;
        }
    })
}

export const forgotpassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/password/forgot-password', payload).then((response) => {
        if(response.data.success){
            let token = response.data.token;
            let email = response.data.email;
            const payloadForgotPassword = {
                token:token,
                email:email,
                fromAdmin: true,
                type : "super-admin"
            }
            return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/forgot-password', payloadForgotPassword).then((response) => {
                if(response.data.success == true){
                    commit('SET_ALERT', 'success', { root: true })
                    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                    return response;
                } else {
                    commit('SET_ALERT', 'warning', { root: true })
                    commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
                    return response;
                }
            }).catch((error) => {
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                context.errors = error.response.data.errors
                return false;
            })
        }
        return response.data.success
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'User Not found', { root: true })
        context.errors = error.response.data.errors
        return false
    })
}

export const resetpassword = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/password/reset', payload).then((response) => {
        // Showing  success message
        commit('SET_ALERT', 'success', { root: true })
        commit('SET_ALERT_MESSAGE', response.data.message, { root: true })
        if(response.data.success){
            const activityLog = window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/activity-log-reset-password', payload).then((res) => {
                if(res.data.success){

                }
            }).catch((error) => {
                commit('SET_ALERT', 'warning', { root: true })
                commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
                context.errors = error.response.data.errors
                return false;
            })
        }
        return response;
    }).catch((error) => {
        console.log(error);
        if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else if(error.response.status == 422){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.password[0], { root: true })
            context.errors = error.response.data.errors
            return false;
        }else {
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            context.errors = error.response.data.errors
            return false;
        }
    })
}

export const getUserAgreementSubscriptionData = ({ commit, dispatch },{payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/getUserAgreementSubscriptionData',payload,{
        headers: {
            //'Authorization': 'Bearer ' + localStorage.getItem("localforage/smartartclient/authtoken")
        }
        }).then((res) => {
            return res.data;
        })
}

export const errorHandler = ({dispatch },payload) => {
    try{
        if(payload === 401){
            dispatch('logoutUnautorizedUser','');
            window.location.href = window.location.origin + "/";
        }
    }catch($e){
        console.log($e);
    }
}

export const getUserCrowdfundingData = ({ commit, dispatch}, {payload, context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-user-crowdfunding-amount',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        //commit("SET_ALERT", "warning", { root: true });
        //commit("SET_ALERT_MESSAGE",  error.response.data.message, { root: true });
        return false;
    });   
};

export const retriveStripData = ({ commit }, {payload}) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL+'api/retrive-stripe-data?session_id='+payload+'&type=superadmin').then((response) => {
        return response.data;
    }).catch((error) => {
       commit("SET_ALERT", "warning", { root: true });
       commit("SET_ALERT_MESSAGE", "Something went wrong " + error, { root: true });
       return false;
    });
};

export const stripeSuccess = ({ commit }, {payload}) => {
   return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL+'api/stripe-crowdfund-payment-success',payload).then((response) => {
       return response.data;
    }).catch((error) => {
       commit("SET_ALERT", "warning", { root: true });
       commit("SET_ALERT_MESSAGE", "Something went wrong " + error, { root: true });
       return false;
    });       
};

export const payToSeller = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/pay-to-seller', payload).then((res) => {
        return res;
    }).catch((error) => {
        if(error.response.status == 422){
            let laraErrors = error.response.data.errors; // this should be errors.
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', laraErrors.email, { root: true })
        } else if(error.response.status == 400){
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', error.response.data.message, { root: true })
        } else {
            commit('SET_ALERT', 'warning', { root: true })
            commit('SET_ALERT_MESSAGE', 'Something went wrong SSO', { root: true })
            context.errors = error.response.data.errors
            return false;
        }
    })
}

export const getSettingsClient = ({ commit, dispatch }, { payload, context }) => {
    let query = payload.edit ? '?edit=true' : '';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/users/profile-details/' + payload.email + query).then((response) => {
        return response.data.details;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}

export const signInUserlogout = ({commit, dispatch},{payload}) =>{
    return window.axios.post(process.env.VUE_APP_SSO_API_URL + 'api/user-sign-in-logout',payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true })
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true })
    })
}

export const getSiteSettingsClient = ({ commit, dispatch }, payload) => {
    let query = payload && payload.edit ? '?edit=true' : '';
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/getSiteSettingsClient' + query).then((response) => {
        return response.data;
    }).catch((error) => {
        commit("SET_ALERT", "warning", { root: true });
        commit("SET_ALERT_MESSAGE", "Something went wrong getSiteSettingsClient " + error, { root: true });
        return false;
    });
}