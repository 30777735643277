export default [
	{
        path: '/buy-orders',
        component: () => import('../components/OrdersParent.vue'),
        meta: {                  
        },
        children:[
            {
                path: '/buy-orders',
                component: () => import('../components/BuyOrders.vue'),
                name: 'buy-orders',
            },
            {
                path: '/buy-orders-info/:id',
                component: () => import('../components/partials/BuyOrderInfo.vue'),
                name: 'buy-orders-info',
            },

            {
                path: '/rent-orders',
                component: () => import('../components/RentOrders.vue'),
                name: 'rent-orders',
            },
            {
                path: '/rent-orders-info/:id',
                component: () => import('../components/partials/RentOrderInfo.vue'),
                name: 'rent-orders-info',
            },

            {
                path: '/custom-orders',
                component: () => import('../components/CustomOrder.vue'),
                name: 'custom-orders',
            },
            {
                path: '/custom-orders-info/:id',
                component: () => import('../components/partials/CustomOrderInfo.vue'),
                name: 'custom-orders-info',
            },
            {
                path: '/strip-success-payment',
                component: () => import('../components/partials/StripePaymentSuccess.vue'),
                name: 'strip-success-payment',
            },
            {
                path: '/paypal-success-payment',
                component: () => import('../components/partials/PaypalSuccess.vue'),
                name: 'order-paypal-success-payment',
            },
            {
                path: '/paypal-error-payment',
                component: () => import('../components/partials/PaypalError.vue'),
                name: 'order-paypal-error-payment',
            },
        ]
    },
    

    

]