export const getCrowdFundingPendingList = ({ commit, dispatch }, query = '') => {
    if(query){
        query = query+'&is_accepted_or_closed="0"';
    }else{
        query = '?is_accepted_or_closed="0"';
    }
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-crowd-funding-list'+ query).then((response) => {
        commit('setCrowdFunding', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getCrowdFundingAcceptedList = ({ commit, dispatch }, query = '') => {
    if(query){
        query = query+'&is_accepted_or_closed=1';
    }else{
        query = '?is_accepted_or_closed=1';
    }
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-crowd-funding-list'+ query).then((response) => {
        commit('setCrowdFunding', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getCrowdFundingClosedList = ({ commit, dispatch }, query = '') => {
    if(query){
        query = query+'&is_accepted_or_closed=2';
    }else{
        query = '?is_accepted_or_closed=2';
    }
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-crowd-funding-list'+ query).then((response) => {
        commit('setCrowdFunding', response.data);
        return true;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const updateCrowdfundingStatus = ({ commit, dispatch }, {payload,context}) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/change-crowdfunding-status/'+ payload.id,payload).then((response) => {
        return response.data;
    }).catch((error) => {
        console.log('error',error);
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        context.gIndicator('stop');
        return false;
    })
}
export const getCrowdFundingDetailsById = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-crowd-funding/'+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const deleteArtProduct = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.delete(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-delete-product/'+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getProductDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-get-art-peice-data/'+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const updateProductDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-update-art-piece-data/'+payload.id, payload).then((response) => {
        return response.data;
    }).catch((error) => {
        context.gIndicator('stop');
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getProfileDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/get-profile-details?id='+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const submitMessage= ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/crowdfunding-messages', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const refundStripePayment= ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/stripe-refund', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const setArtPieceStatus = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/update-art-piece-status', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const refundBankTransferPayment = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/pay-to-buyer', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const addCommentRequest = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/add-comment', payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const totalFundAmount = ({ commit, dispatch }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/admin-total-funds').then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getCancelOrderList = ({ commit, dispatch }, query = '') => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/cancelled-order-list'+ query).then((response) => {
        commit('setCancelOrder', response.data);
        commit('setCancelOrderReturnToBuyerFundsInfo',response.data.funds_return_to_buyer_info);
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const getCancelOrderDetails = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.get(process.env.VUE_APP_SMART_ART_API_URL + 'api/cancelled-order-details-by-id/'+payload.id+'?order_id='+payload.order_id).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const setPaymentConfirm = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/crowdfund-confirmed-payment/'+payload.id).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}
export const receiveAsBuyer = ({ commit, dispatch }, { payload, context }) => {
    return window.axios.post(process.env.VUE_APP_SMART_ART_API_URL + 'api/receive-as-buyer-or-seller/'+payload.id,payload).then((response) => {
        return response.data;
    }).catch((error) => {
        commit('SET_ALERT', 'warning', { root: true });
        commit('SET_ALERT_MESSAGE', 'Something went wrong', { root: true });
        return false;
    })
}