export default {
	list:{
		data: null,
		links: null,
		meta: null,
		current_page:null
	},
	status:[
		{'value' : '','name' : 'All'},
		{'value' : 'Purchase Request','name' : 'Purchase Request'},
		{'value' : 'Payment Requested','name' : 'Payment Requested'},
		{'value' : 'Payment','name' : 'Payment'},
		{'value' : 'Order Confirmed','name' : 'Order Confirmed'},
		{'value' : 'Shipped','name' : 'Shipped'},
		{'value' : 'Delivered','name' : 'Delivered'},
		{'value' : 'Rejected','name' : 'Rejected'},
		{'value' : 'Order Cancelled','name' : 'Order Cancelled'},
		{'value' : 'Closed','name' : 'Closed'},
	]
}
